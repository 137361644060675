import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Review from "./Review"

const Box = styled.div`
  max-width: 790px;
  margin: 64px auto;

  h2 {
    color: ${props => props.theme.black};
  }

  @media (min-width: 1024px) {
    margin: 0 0 0 20px;
    max-width: calc(50% - 20px);

    h2 {
      margin-top: 0;
      color: ${props => props.theme.darkBlue};
    }
  }
`

export default function YomiReview() {

  return (
    <div
      className="container"
      css={css`
        @media (min-width: 1024px) {
          padding-bottom: 64px;
        }
      `}
    >
      <div
        css={css`
          margin-top: 64px;

          @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 128px 0;
          }
        `}
      >
        <StaticImage
          src="../images/yomi-1.jpg"
          placeholder="blurred"
          width={791}
          height={690}
          css={css`
            margin: 0 auto;
            display: block;
            max-width: 791px;

            @media (min-width: 1024px) {
              margin: 0;
            }
          `}
        />
        <Box>
          <h2>Yomi is bringing the power of surgical robotics to dentistry</h2>
          <p>
            Robot-assisted surgery is fast becoming a standard of care across
            surgical disciplines, offering unprecedented improvements in:
          </p>
          <ul>
            <li>Procedure Planning </li>
            <li>Augmented Anatomical Visualization </li>
            <li>Haptic Guided Surgery </li>
            <li>
              Over a million patients a year are treated using robotic surgery,
              across multiple medical disciplines.
            </li>
          </ul>
        </Box>
      </div>
      
    </div>
  )
}
