import React, {useRef} from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import YomiBenefits from "../components/YomiBenefits"
import YomiReview from "../components/YomiReview"
import Review from '../components/Review'

export default function YomiPage() {
  const review = {
    name: "Nima Massoomi, DMD, MEd, MD",
    text: "“Yomi allows you to feel if you are going into the right direction. If you are going in the wrong direction, you will feel resistance. It allows the surgeon to know exactly which direction to go to place the implant.”",
    video: "3uXfOdoOpmw",
  }

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView() 
  return (
    <Layout executeScroll={executeScroll} yomiPage displayReviews>
      <Seo title="Yomi - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO" />
      <YomiReview />
      <div ref={myRef}>
        <Review review={review} />
      </div> 
     <YomiBenefits />
    </Layout>
  )
}
