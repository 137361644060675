import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import checkmark from "../images/checkmark.png"

const Section = styled.section`
  padding: 64px max(16px, calc((100vw - 1660px) / 2));
  background: ${props => props.theme.bgLight};

  > div:first-of-type {
    max-width: 652px;
    display: block;
    margin: 0 auto 64px;
  }

  @media (min-width: 1280px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-of-type {
      margin: 0;
      order: 1;
    }
  }
`

const Box = styled.div`
  max-width: 652px;
  margin: 0 auto;

  @media (min-width: 1280px) {
    margin: 0 2rem 0 0;
    max-width: 400px;

    :last-of-type {
      order: 3;
      margin: 0 0 0 2rem;
    }
  }

  @media (min-width: 1640px) {
    &,
    :last-of-type {
      margin: 0;
      max-width: 420px;
    }
  }
`

const Point = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  border-top: ${props => `2px solid ${props.theme.lighterGrey}`};

  h3 {
    line-height: 133%;
    letter-spacing: -0.5px;
    margin-left: 2rem;
  }

  :first-of-type {
    border-top: none;
  }

  :last-of-type {
    border-bottom: ${props =>
      props.first ? `2px solid ${props.theme.lighterGrey}` : "none"};
  }

  @media (min-width: 1280px) {
    h3 {
      order: ${props => (props.first ? -1 : 0)};
      margin: ${props => (props.first ? "0 2rem 0 0" : " 0 0 0 2rem")};
    }
  }
`
export default function YomiBenefits() {
  return (
    <Section>
      <StaticImage
        src="../images/yomi.png"
        placeholder="blurred"
        alt="yomi machine"
        css={css``}
      />
      <Box>
        <Point first>
          <img src={checkmark} alt="" />
          <h3>Haptic guidance technology</h3>
        </Point>
        <Point first>
          <img src={checkmark} alt="" />
          <h3>Physical guidance through implant</h3>
        </Point>
        <Point first>
          <img src={checkmark} alt="" />
          <h3>Prevents drill deviation</h3>
        </Point>
      </Box>
      <Box>
        <Point>
          <img src={checkmark} alt="" />
          <h3>Implant planning software</h3>
        </Point>
        <Point>
          <img src={checkmark} alt="" />
          <h3>Plan all aspects for ideal surgery location</h3>
        </Point>
        <Point>
          <img src={checkmark} alt="" />
          <h3>Visualization of virtual teeth for better aesthetic planning</h3>
        </Point>
      </Box>
    </Section>
  )
}
